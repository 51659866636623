import { default as indexsz5W6HgR8iMeta } from "A:/Solutions/NewVision/newvision.ge/pages/index.vue?macro=true";
import { default as aboutUaOKcoVKapMeta } from "A:/Solutions/NewVision/newvision.ge/pages/about.vue?macro=true";
import { default as admission1Epqvo1P9jMeta } from "A:/Solutions/NewVision/newvision.ge/pages/admission.vue?macro=true";
import { default as career1Zy2hHJqCGMeta } from "A:/Solutions/NewVision/newvision.ge/pages/career.vue?macro=true";
import { default as _91_46_46_46slug_93dUbylgWFEzMeta } from "A:/Solutions/NewVision/newvision.ge/pages/centers/[...slug].vue?macro=true";
import { default as index8iMjShFsszMeta } from "A:/Solutions/NewVision/newvision.ge/pages/centers/index.vue?macro=true";
import { default as _91_46_46_46slug_93QdwUn57LkhMeta } from "A:/Solutions/NewVision/newvision.ge/pages/eng/[...slug].vue?macro=true";
import { default as indexkx3tQLhU6RMeta } from "A:/Solutions/NewVision/newvision.ge/pages/eng/index.vue?macro=true";
import { default as _91_46_46_46slug_93s5TC7fENQuMeta } from "A:/Solutions/NewVision/newvision.ge/pages/events/[...slug].vue?macro=true";
import { default as indexmquS7RjkYzMeta } from "A:/Solutions/NewVision/newvision.ge/pages/events/index.vue?macro=true";
import { default as _91_46_46_46slug_93PAHANvuKUqMeta } from "A:/Solutions/NewVision/newvision.ge/pages/forms/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93Ic4K6r0NNOMeta } from "A:/Solutions/NewVision/newvision.ge/pages/geo/[...slug].vue?macro=true";
import { default as indexfUpkL5TxGgMeta } from "A:/Solutions/NewVision/newvision.ge/pages/geo/index.vue?macro=true";
import { default as exchange_45programsVgVTIzWFWEMeta } from "A:/Solutions/NewVision/newvision.ge/pages/international-cooperation/exchange-programs.vue?macro=true";
import { default as indexzwqWoiv3QRMeta } from "A:/Solutions/NewVision/newvision.ge/pages/international-cooperation/index.vue?macro=true";
import { default as international_45partnersrkmJcnuETNMeta } from "A:/Solutions/NewVision/newvision.ge/pages/international-cooperation/international-partners.vue?macro=true";
import { default as networks_45and_45associationsrs8cm7npsjMeta } from "A:/Solutions/NewVision/newvision.ge/pages/international-cooperation/networks-and-associations.vue?macro=true";
import { default as organizationsO20dgFgk0xMeta } from "A:/Solutions/NewVision/newvision.ge/pages/international-cooperation/partnership/organizations.vue?macro=true";
import { default as universitiess7UVjDhaetMeta } from "A:/Solutions/NewVision/newvision.ge/pages/international-cooperation/partnership/universities.vue?macro=true";
import { default as exchange_45programsKgMJu43VOVMeta } from "A:/Solutions/NewVision/newvision.ge/pages/international-cooperation/programs/exchange-programs.vue?macro=true";
import { default as index79jzVHFrfnMeta } from "A:/Solutions/NewVision/newvision.ge/pages/journal/[journalSlug]/[publicationSlug]/[articleSlug]/index.vue?macro=true";
import { default as indexv8uQ9qBiWqMeta } from "A:/Solutions/NewVision/newvision.ge/pages/journal/[journalSlug]/[publicationSlug]/index.vue?macro=true";
import { default as indexLjez0rtbWyMeta } from "A:/Solutions/NewVision/newvision.ge/pages/journal/[journalSlug]/index.vue?macro=true";
import { default as indexlxveCGW2pXMeta } from "A:/Solutions/NewVision/newvision.ge/pages/journal/[journalSlug]/page/[pageSlug]/index.vue?macro=true";
import { default as allRO1Amfu4M0Meta } from "A:/Solutions/NewVision/newvision.ge/pages/journal/[journalSlug]/page/all.vue?macro=true";
import { default as current6XdbH7XilXMeta } from "A:/Solutions/NewVision/newvision.ge/pages/journal/[journalSlug]/page/current.vue?macro=true";
import { default as homePHvRWqG9wUMeta } from "A:/Solutions/NewVision/newvision.ge/pages/journal/[journalSlug]/page/home.vue?macro=true";
import { default as missionBBashF5lUCMeta } from "A:/Solutions/NewVision/newvision.ge/pages/mission.vue?macro=true";
import { default as _91_46_46_46slug_93JhhsriiiqsMeta } from "A:/Solutions/NewVision/newvision.ge/pages/news-TEST2/[...slug].vue?macro=true";
import { default as indexEa4ruYJBu0Meta } from "A:/Solutions/NewVision/newvision.ge/pages/news-TEST2/index.vue?macro=true";
import { default as _91_46_46_46slug_93vseYvGcNL6Meta } from "A:/Solutions/NewVision/newvision.ge/pages/news/[...slug].vue?macro=true";
import { default as indexzy3vXQ1a2hMeta } from "A:/Solutions/NewVision/newvision.ge/pages/news/index.vue?macro=true";
import { default as indexd4nb6jHMyOMeta } from "A:/Solutions/NewVision/newvision.ge/pages/page/[...slug]/index.vue?macro=true";
import { default as admission_45consent9dfty0N2EqMeta } from "A:/Solutions/NewVision/newvision.ge/pages/page/admission-consent.vue?macro=true";
import { default as participation_45consentBY2afoiR5mMeta } from "A:/Solutions/NewVision/newvision.ge/pages/page/participation-consent.vue?macro=true";
import { default as indexEW1IClFMMeMeta } from "A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/charter-and-regulations/index.vue?macro=true";
import { default as indexoXLlq75migMeta } from "A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/charter/index.vue?macro=true";
import { default as indexbLqb6ldEooMeta } from "A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/diversity-and-equality/index.vue?macro=true";
import { default as indexOFayB8AJnNMeta } from "A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/index.vue?macro=true";
import { default as indexohdD7bQrjEMeta } from "A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/institutional-accreditation/index.vue?macro=true";
import { default as academic_45freedompaQdvaeOfBMeta } from "A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/policies/academic-freedom.vue?macro=true";
import { default as diversity_45and_45equalityRZnpDlLFdXMeta } from "A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/policies/diversity-and-equality.vue?macro=true";
import { default as energy_45efficiency1zJiTYl49kMeta } from "A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/policies/energy-efficiency.vue?macro=true";
import { default as gender_45equality8g5GuoJreKMeta } from "A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/policies/gender-equality.vue?macro=true";
import { default as indexR4WUzNStcoMeta } from "A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/policies/index.vue?macro=true";
import { default as sustainability6GfuSB3lQFMeta } from "A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/policies/sustainability.vue?macro=true";
import { default as indexcPNbUTepD2Meta } from "A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/reports/index.vue?macro=true";
import { default as index9xiC1FTbCiMeta } from "A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/statements/index.vue?macro=true";
import { default as _91slug_934juLYBTiTdMeta } from "A:/Solutions/NewVision/newvision.ge/pages/post/[slug].vue?macro=true";
import { default as indexaqDHvfOvqaMeta } from "A:/Solutions/NewVision/newvision.ge/pages/post/index.vue?macro=true";
import { default as _91slug_93RwU2F1ZBhyMeta } from "A:/Solutions/NewVision/newvision.ge/pages/programs/[programSlug]/[slug].vue?macro=true";
import { default as bachelorTjQsOovc6aMeta } from "A:/Solutions/NewVision/newvision.ge/pages/programs/bachelor.vue?macro=true";
import { default as mastermhOBGKAUEAMeta } from "A:/Solutions/NewVision/newvision.ge/pages/programs/master.vue?macro=true";
import { default as non_45degreeihafvA3mW5Meta } from "A:/Solutions/NewVision/newvision.ge/pages/programs/non-degree.vue?macro=true";
import { default as one_45cycleanIXxcYJLdMeta } from "A:/Solutions/NewVision/newvision.ge/pages/programs/one-cycle.vue?macro=true";
import { default as phdm4SKUKBJyFMeta } from "A:/Solutions/NewVision/newvision.ge/pages/programs/phd.vue?macro=true";
import { default as _91_46_46_46slug_93TjG90ApfV7Meta } from "A:/Solutions/NewVision/newvision.ge/pages/projects/[...slug].vue?macro=true";
import { default as indexhGAO1p7SMmMeta } from "A:/Solutions/NewVision/newvision.ge/pages/projects/index.vue?macro=true";
import { default as about8bvNeqABz7Meta } from "A:/Solutions/NewVision/newvision.ge/pages/projects/legal-ethics-chair/about.vue?macro=true";
import { default as activitiesjXJDYS9Ks2Meta } from "A:/Solutions/NewVision/newvision.ge/pages/projects/legal-ethics-chair/activities.vue?macro=true";
import { default as contactAnufUMPGPfMeta } from "A:/Solutions/NewVision/newvision.ge/pages/projects/legal-ethics-chair/contact.vue?macro=true";
import { default as galleryU3cfT9RJcrMeta } from "A:/Solutions/NewVision/newvision.ge/pages/projects/legal-ethics-chair/gallery.vue?macro=true";
import { default as legal_45aid_45clinic1MObu3IUgUMeta } from "A:/Solutions/NewVision/newvision.ge/pages/projects/legal-ethics-chair/legal-aid-clinic.vue?macro=true";
import { default as _91slug_93xn605EHwAzMeta } from "A:/Solutions/NewVision/newvision.ge/pages/projects/legal-ethics-chair/library/[slug].vue?macro=true";
import { default as indexKSZdVtJOTdMeta } from "A:/Solutions/NewVision/newvision.ge/pages/projects/legal-ethics-chair/library/index.vue?macro=true";
import { default as newsqnCJJEmt2vMeta } from "A:/Solutions/NewVision/newvision.ge/pages/projects/legal-ethics-chair/news.vue?macro=true";
import { default as staffM4CKS783hLMeta } from "A:/Solutions/NewVision/newvision.ge/pages/projects/legal-ethics-chair/staff.vue?macro=true";
import { default as _91_46_46_46slug_93nBaNwGj3LiMeta } from "A:/Solutions/NewVision/newvision.ge/pages/projects/olympiads/[...slug].vue?macro=true";
import { default as indexfR1Qz6P2U9Meta } from "A:/Solutions/NewVision/newvision.ge/pages/projects/olympiads/index.vue?macro=true";
import { default as indexNe2uir0HXPMeta } from "A:/Solutions/NewVision/newvision.ge/pages/research-and-resources/e-resources/index.vue?macro=true";
import { default as indexTgMWQ6iWhHMeta } from "A:/Solutions/NewVision/newvision.ge/pages/research-and-resources/index.vue?macro=true";
import { default as _91_46_46_46slug_93X67RxEogn1Meta } from "A:/Solutions/NewVision/newvision.ge/pages/research-and-resources/publications/[...slug].vue?macro=true";
import { default as indexERsg6trUP9Meta } from "A:/Solutions/NewVision/newvision.ge/pages/research-and-resources/publications/index.vue?macro=true";
import { default as centers22OtpV5J9CMeta } from "A:/Solutions/NewVision/newvision.ge/pages/research-and-resources/research/centers.vue?macro=true";
import { default as indexqovqhIiKmYMeta } from "A:/Solutions/NewVision/newvision.ge/pages/research-and-resources/research/index.vue?macro=true";
import { default as projectsbeqfDoSSD6Meta } from "A:/Solutions/NewVision/newvision.ge/pages/research-and-resources/research/projects.vue?macro=true";
import { default as descnetY96Fm7G7RwMeta } from "A:/Solutions/NewVision/newvision.ge/pages/research-projects/descnet.vue?macro=true";
import { default as jean_45monnet6d659i4dAMMeta } from "A:/Solutions/NewVision/newvision.ge/pages/research-projects/jean-monnet.vue?macro=true";
import { default as _91slug_93OFE4nc1NrTMeta } from "A:/Solutions/NewVision/newvision.ge/pages/schools/[slug].vue?macro=true";
import { default as _91slug_93MM94pZ1EZyMeta } from "A:/Solutions/NewVision/newvision.ge/pages/staff/[slug].vue?macro=true";
import { default as component_45stubPFFdYwiFpzMeta } from "A:/Solutions/NewVision/newvision.ge/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.10_eslint@8.57.1_rollup@4.24.0_sass@1.79.4_typescript@5.6.2_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubPFFdYwiFpz } from "A:/Solutions/NewVision/newvision.ge/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.10_eslint@8.57.1_rollup@4.24.0_sass@1.79.4_typescript@5.6.2_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index",
    path: "/",
    meta: indexsz5W6HgR8iMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/index.vue")
  },
  {
    name: "about___en",
    path: "/en/about",
    meta: aboutUaOKcoVKapMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/about.vue")
  },
  {
    name: "about___ka",
    path: "/ka/about",
    meta: aboutUaOKcoVKapMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/about.vue")
  },
  {
    name: "admission___en",
    path: "/en/admission",
    meta: admission1Epqvo1P9jMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/admission.vue")
  },
  {
    name: "admission___ka",
    path: "/ka/admission",
    meta: admission1Epqvo1P9jMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/admission.vue")
  },
  {
    name: "career___en",
    path: "/en/career",
    meta: career1Zy2hHJqCGMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/career.vue")
  },
  {
    name: "career___ka",
    path: "/ka/career",
    meta: career1Zy2hHJqCGMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/career.vue")
  },
  {
    name: "centers-slug___en",
    path: "/en/centers/:slug(.*)*",
    meta: _91_46_46_46slug_93dUbylgWFEzMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/centers/[...slug].vue")
  },
  {
    name: "centers-slug___ka",
    path: "/ka/centers/:slug(.*)*",
    meta: _91_46_46_46slug_93dUbylgWFEzMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/centers/[...slug].vue")
  },
  {
    name: "centers___en",
    path: "/en/centers",
    meta: index8iMjShFsszMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/centers/index.vue")
  },
  {
    name: "centers___ka",
    path: "/ka/centers",
    meta: index8iMjShFsszMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/centers/index.vue")
  },
  {
    name: "eng-slug___en",
    path: "/en/eng/:slug(.*)*",
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/eng/[...slug].vue")
  },
  {
    name: "eng-slug___ka",
    path: "/ka/eng/:slug(.*)*",
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/eng/[...slug].vue")
  },
  {
    name: "eng___en",
    path: "/en/eng",
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/eng/index.vue")
  },
  {
    name: "eng___ka",
    path: "/ka/eng",
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/eng/index.vue")
  },
  {
    name: "events-slug___en",
    path: "/en/events/:slug(.*)*",
    meta: _91_46_46_46slug_93s5TC7fENQuMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/events/[...slug].vue")
  },
  {
    name: "events-slug___ka",
    path: "/ka/events/:slug(.*)*",
    meta: _91_46_46_46slug_93s5TC7fENQuMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/events/[...slug].vue")
  },
  {
    name: "events___en",
    path: "/en/events",
    meta: indexmquS7RjkYzMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/events/index.vue")
  },
  {
    name: "events___ka",
    path: "/ka/events",
    meta: indexmquS7RjkYzMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/events/index.vue")
  },
  {
    name: "forms-slug___en",
    path: "/en/forms/:slug(.*)*",
    meta: _91_46_46_46slug_93PAHANvuKUqMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/forms/[...slug].vue")
  },
  {
    name: "forms-slug___ka",
    path: "/ka/forms/:slug(.*)*",
    meta: _91_46_46_46slug_93PAHANvuKUqMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/forms/[...slug].vue")
  },
  {
    name: "geo-slug___en",
    path: "/en/geo/:slug(.*)*",
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/geo/[...slug].vue")
  },
  {
    name: "geo-slug___ka",
    path: "/ka/geo/:slug(.*)*",
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/geo/[...slug].vue")
  },
  {
    name: "geo___en",
    path: "/en/geo",
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/geo/index.vue")
  },
  {
    name: "geo___ka",
    path: "/ka/geo",
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/geo/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexsz5W6HgR8iMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/index.vue")
  },
  {
    name: "index___ka",
    path: "/ka",
    meta: indexsz5W6HgR8iMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/index.vue")
  },
  {
    name: "international-cooperation-exchange-programs___en",
    path: "/en/international-cooperation/exchange-programs",
    meta: exchange_45programsVgVTIzWFWEMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/international-cooperation/exchange-programs.vue")
  },
  {
    name: "international-cooperation-exchange-programs___ka",
    path: "/ka/international-cooperation/exchange-programs",
    meta: exchange_45programsVgVTIzWFWEMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/international-cooperation/exchange-programs.vue")
  },
  {
    name: "international-cooperation___en",
    path: "/en/international-cooperation",
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/international-cooperation/index.vue")
  },
  {
    name: "international-cooperation___ka",
    path: "/ka/international-cooperation",
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/international-cooperation/index.vue")
  },
  {
    name: "international-cooperation-international-partners___en",
    path: "/en/international-cooperation/international-partners",
    meta: international_45partnersrkmJcnuETNMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/international-cooperation/international-partners.vue")
  },
  {
    name: "international-cooperation-international-partners___ka",
    path: "/ka/international-cooperation/international-partners",
    meta: international_45partnersrkmJcnuETNMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/international-cooperation/international-partners.vue")
  },
  {
    name: "international-cooperation-networks-and-associations___en",
    path: "/en/international-cooperation/networks-and-associations",
    meta: networks_45and_45associationsrs8cm7npsjMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/international-cooperation/networks-and-associations.vue")
  },
  {
    name: "international-cooperation-networks-and-associations___ka",
    path: "/ka/international-cooperation/networks-and-associations",
    meta: networks_45and_45associationsrs8cm7npsjMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/international-cooperation/networks-and-associations.vue")
  },
  {
    name: "international-cooperation-partnership-organizations___en",
    path: "/en/international-cooperation/partnership/organizations",
    meta: organizationsO20dgFgk0xMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/international-cooperation/partnership/organizations.vue")
  },
  {
    name: "international-cooperation-partnership-organizations___ka",
    path: "/ka/international-cooperation/partnership/organizations",
    meta: organizationsO20dgFgk0xMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/international-cooperation/partnership/organizations.vue")
  },
  {
    name: "international-cooperation-partnership-universities___en",
    path: "/en/international-cooperation/partnership/universities",
    meta: universitiess7UVjDhaetMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/international-cooperation/partnership/universities.vue")
  },
  {
    name: "international-cooperation-partnership-universities___ka",
    path: "/ka/international-cooperation/partnership/universities",
    meta: universitiess7UVjDhaetMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/international-cooperation/partnership/universities.vue")
  },
  {
    name: "international-cooperation-programs-exchange-programs___en",
    path: "/en/international-cooperation/programs/exchange-programs",
    meta: exchange_45programsKgMJu43VOVMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/international-cooperation/programs/exchange-programs.vue")
  },
  {
    name: "international-cooperation-programs-exchange-programs___ka",
    path: "/ka/international-cooperation/programs/exchange-programs",
    meta: exchange_45programsKgMJu43VOVMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/international-cooperation/programs/exchange-programs.vue")
  },
  {
    name: "journal-journalSlug-publicationSlug-articleSlug___en",
    path: "/en/journal/:journalSlug()/:publicationSlug()/:articleSlug()",
    meta: index79jzVHFrfnMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/journal/[journalSlug]/[publicationSlug]/[articleSlug]/index.vue")
  },
  {
    name: "journal-journalSlug-publicationSlug-articleSlug___ka",
    path: "/ka/journal/:journalSlug()/:publicationSlug()/:articleSlug()",
    meta: index79jzVHFrfnMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/journal/[journalSlug]/[publicationSlug]/[articleSlug]/index.vue")
  },
  {
    name: "journal-journalSlug-publicationSlug___en",
    path: "/en/journal/:journalSlug()/:publicationSlug()",
    meta: indexv8uQ9qBiWqMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/journal/[journalSlug]/[publicationSlug]/index.vue")
  },
  {
    name: "journal-journalSlug-publicationSlug___ka",
    path: "/ka/journal/:journalSlug()/:publicationSlug()",
    meta: indexv8uQ9qBiWqMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/journal/[journalSlug]/[publicationSlug]/index.vue")
  },
  {
    name: "journal-journalSlug___en",
    path: "/en/journal/:journalSlug()",
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/journal/[journalSlug]/index.vue")
  },
  {
    name: "journal-journalSlug___ka",
    path: "/ka/journal/:journalSlug()",
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/journal/[journalSlug]/index.vue")
  },
  {
    name: "journal-journalSlug-page-pageSlug___en",
    path: "/en/journal/:journalSlug()/page/:pageSlug()",
    meta: indexlxveCGW2pXMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/journal/[journalSlug]/page/[pageSlug]/index.vue")
  },
  {
    name: "journal-journalSlug-page-pageSlug___ka",
    path: "/ka/journal/:journalSlug()/page/:pageSlug()",
    meta: indexlxveCGW2pXMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/journal/[journalSlug]/page/[pageSlug]/index.vue")
  },
  {
    name: "journal-journalSlug-page-all___en",
    path: "/en/journal/:journalSlug()/page/all",
    meta: allRO1Amfu4M0Meta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/journal/[journalSlug]/page/all.vue")
  },
  {
    name: "journal-journalSlug-page-all___ka",
    path: "/ka/journal/:journalSlug()/page/all",
    meta: allRO1Amfu4M0Meta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/journal/[journalSlug]/page/all.vue")
  },
  {
    name: "journal-journalSlug-page-current___en",
    path: "/en/journal/:journalSlug()/page/current",
    meta: current6XdbH7XilXMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/journal/[journalSlug]/page/current.vue")
  },
  {
    name: "journal-journalSlug-page-current___ka",
    path: "/ka/journal/:journalSlug()/page/current",
    meta: current6XdbH7XilXMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/journal/[journalSlug]/page/current.vue")
  },
  {
    name: "journal-journalSlug-page-home___en",
    path: "/en/journal/:journalSlug()/page/home",
    meta: homePHvRWqG9wUMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/journal/[journalSlug]/page/home.vue")
  },
  {
    name: "journal-journalSlug-page-home___ka",
    path: "/ka/journal/:journalSlug()/page/home",
    meta: homePHvRWqG9wUMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/journal/[journalSlug]/page/home.vue")
  },
  {
    name: "mission___en",
    path: "/en/mission",
    meta: missionBBashF5lUCMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/mission.vue")
  },
  {
    name: "mission___ka",
    path: "/ka/mission",
    meta: missionBBashF5lUCMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/mission.vue")
  },
  {
    name: "news-TEST2-slug___en",
    path: "/en/news-TEST2/:slug(.*)*",
    meta: _91_46_46_46slug_93JhhsriiiqsMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/news-TEST2/[...slug].vue")
  },
  {
    name: "news-TEST2-slug___ka",
    path: "/ka/news-TEST2/:slug(.*)*",
    meta: _91_46_46_46slug_93JhhsriiiqsMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/news-TEST2/[...slug].vue")
  },
  {
    name: "news-TEST2___en",
    path: "/en/news-TEST2",
    meta: indexEa4ruYJBu0Meta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/news-TEST2/index.vue")
  },
  {
    name: "news-TEST2___ka",
    path: "/ka/news-TEST2",
    meta: indexEa4ruYJBu0Meta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/news-TEST2/index.vue")
  },
  {
    name: "news-slug___en",
    path: "/en/news/:slug(.*)*",
    meta: _91_46_46_46slug_93vseYvGcNL6Meta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/news/[...slug].vue")
  },
  {
    name: "news-slug___ka",
    path: "/ka/news/:slug(.*)*",
    meta: _91_46_46_46slug_93vseYvGcNL6Meta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/news/[...slug].vue")
  },
  {
    name: "news___en",
    path: "/en/news",
    meta: indexzy3vXQ1a2hMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/news/index.vue")
  },
  {
    name: "news___ka",
    path: "/ka/news",
    meta: indexzy3vXQ1a2hMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/news/index.vue")
  },
  {
    name: "page-slug___en",
    path: "/en/page/:slug(.*)*",
    meta: indexd4nb6jHMyOMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/page/[...slug]/index.vue")
  },
  {
    name: "page-slug___ka",
    path: "/ka/page/:slug(.*)*",
    meta: indexd4nb6jHMyOMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/page/[...slug]/index.vue")
  },
  {
    name: "page-admission-consent___en",
    path: "/en/page/admission-consent",
    meta: admission_45consent9dfty0N2EqMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/page/admission-consent.vue")
  },
  {
    name: "page-admission-consent___ka",
    path: "/ka/page/admission-consent",
    meta: admission_45consent9dfty0N2EqMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/page/admission-consent.vue")
  },
  {
    name: "page-participation-consent___en",
    path: "/en/page/participation-consent",
    meta: participation_45consentBY2afoiR5mMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/page/participation-consent.vue")
  },
  {
    name: "page-participation-consent___ka",
    path: "/ka/page/participation-consent",
    meta: participation_45consentBY2afoiR5mMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/page/participation-consent.vue")
  },
  {
    name: "policies-and-reports-charter-and-regulations___en",
    path: "/en/policies-and-reports/charter-and-regulations",
    meta: indexEW1IClFMMeMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/charter-and-regulations/index.vue")
  },
  {
    name: "policies-and-reports-charter-and-regulations___ka",
    path: "/ka/policies-and-reports/charter-and-regulations",
    meta: indexEW1IClFMMeMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/charter-and-regulations/index.vue")
  },
  {
    name: "policies-and-reports-charter___en",
    path: "/en/policies-and-reports/charter",
    meta: indexoXLlq75migMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/charter/index.vue")
  },
  {
    name: "policies-and-reports-charter___ka",
    path: "/ka/policies-and-reports/charter",
    meta: indexoXLlq75migMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/charter/index.vue")
  },
  {
    name: "policies-and-reports-diversity-and-equality___en",
    path: "/en/policies-and-reports/diversity-and-equality",
    meta: indexbLqb6ldEooMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/diversity-and-equality/index.vue")
  },
  {
    name: "policies-and-reports-diversity-and-equality___ka",
    path: "/ka/policies-and-reports/diversity-and-equality",
    meta: indexbLqb6ldEooMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/diversity-and-equality/index.vue")
  },
  {
    name: "policies-and-reports___en",
    path: "/en/policies-and-reports",
    meta: indexOFayB8AJnNMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/index.vue")
  },
  {
    name: "policies-and-reports___ka",
    path: "/ka/policies-and-reports",
    meta: indexOFayB8AJnNMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/index.vue")
  },
  {
    name: "policies-and-reports-institutional-accreditation___en",
    path: "/en/policies-and-reports/institutional-accreditation",
    meta: indexohdD7bQrjEMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/institutional-accreditation/index.vue")
  },
  {
    name: "policies-and-reports-institutional-accreditation___ka",
    path: "/ka/policies-and-reports/institutional-accreditation",
    meta: indexohdD7bQrjEMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/institutional-accreditation/index.vue")
  },
  {
    name: "policies-and-reports-policies-academic-freedom___en",
    path: "/en/policies-and-reports/policies/academic-freedom",
    meta: academic_45freedompaQdvaeOfBMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/policies/academic-freedom.vue")
  },
  {
    name: "policies-and-reports-policies-academic-freedom___ka",
    path: "/ka/policies-and-reports/policies/academic-freedom",
    meta: academic_45freedompaQdvaeOfBMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/policies/academic-freedom.vue")
  },
  {
    name: "policies-and-reports-policies-diversity-and-equality___en",
    path: "/en/policies-and-reports/policies/diversity-and-equality",
    meta: diversity_45and_45equalityRZnpDlLFdXMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/policies/diversity-and-equality.vue")
  },
  {
    name: "policies-and-reports-policies-diversity-and-equality___ka",
    path: "/ka/policies-and-reports/policies/diversity-and-equality",
    meta: diversity_45and_45equalityRZnpDlLFdXMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/policies/diversity-and-equality.vue")
  },
  {
    name: "policies-and-reports-policies-energy-efficiency___en",
    path: "/en/policies-and-reports/policies/energy-efficiency",
    meta: energy_45efficiency1zJiTYl49kMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/policies/energy-efficiency.vue")
  },
  {
    name: "policies-and-reports-policies-energy-efficiency___ka",
    path: "/ka/policies-and-reports/policies/energy-efficiency",
    meta: energy_45efficiency1zJiTYl49kMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/policies/energy-efficiency.vue")
  },
  {
    name: "policies-and-reports-policies-gender-equality___en",
    path: "/en/policies-and-reports/policies/gender-equality",
    meta: gender_45equality8g5GuoJreKMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/policies/gender-equality.vue")
  },
  {
    name: "policies-and-reports-policies-gender-equality___ka",
    path: "/ka/policies-and-reports/policies/gender-equality",
    meta: gender_45equality8g5GuoJreKMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/policies/gender-equality.vue")
  },
  {
    name: "policies-and-reports-policies___en",
    path: "/en/policies-and-reports/policies",
    meta: indexR4WUzNStcoMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/policies/index.vue")
  },
  {
    name: "policies-and-reports-policies___ka",
    path: "/ka/policies-and-reports/policies",
    meta: indexR4WUzNStcoMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/policies/index.vue")
  },
  {
    name: "policies-and-reports-policies-sustainability___en",
    path: "/en/policies-and-reports/policies/sustainability",
    meta: sustainability6GfuSB3lQFMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/policies/sustainability.vue")
  },
  {
    name: "policies-and-reports-policies-sustainability___ka",
    path: "/ka/policies-and-reports/policies/sustainability",
    meta: sustainability6GfuSB3lQFMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/policies/sustainability.vue")
  },
  {
    name: "policies-and-reports-reports___en",
    path: "/en/policies-and-reports/reports",
    meta: indexcPNbUTepD2Meta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/reports/index.vue")
  },
  {
    name: "policies-and-reports-reports___ka",
    path: "/ka/policies-and-reports/reports",
    meta: indexcPNbUTepD2Meta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/reports/index.vue")
  },
  {
    name: "policies-and-reports-statements___en",
    path: "/en/policies-and-reports/statements",
    meta: index9xiC1FTbCiMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/statements/index.vue")
  },
  {
    name: "policies-and-reports-statements___ka",
    path: "/ka/policies-and-reports/statements",
    meta: index9xiC1FTbCiMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/policies-and-reports/statements/index.vue")
  },
  {
    name: "post-slug___en",
    path: "/en/post/:slug()",
    meta: _91slug_934juLYBTiTdMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/post/[slug].vue")
  },
  {
    name: "post-slug___ka",
    path: "/ka/post/:slug()",
    meta: _91slug_934juLYBTiTdMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/post/[slug].vue")
  },
  {
    name: "post___en",
    path: "/en/post",
    meta: indexaqDHvfOvqaMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/post/index.vue")
  },
  {
    name: "post___ka",
    path: "/ka/post",
    meta: indexaqDHvfOvqaMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/post/index.vue")
  },
  {
    name: "programs-programSlug-slug___en",
    path: "/en/programs/:programSlug()/:slug()",
    meta: _91slug_93RwU2F1ZBhyMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/programs/[programSlug]/[slug].vue")
  },
  {
    name: "programs-programSlug-slug___ka",
    path: "/ka/programs/:programSlug()/:slug()",
    meta: _91slug_93RwU2F1ZBhyMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/programs/[programSlug]/[slug].vue")
  },
  {
    name: "programs-bachelor___en",
    path: "/en/programs/bachelor",
    meta: bachelorTjQsOovc6aMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/programs/bachelor.vue")
  },
  {
    name: "programs-bachelor___ka",
    path: "/ka/programs/bachelor",
    meta: bachelorTjQsOovc6aMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/programs/bachelor.vue")
  },
  {
    name: "programs-master___en",
    path: "/en/programs/master",
    meta: mastermhOBGKAUEAMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/programs/master.vue")
  },
  {
    name: "programs-master___ka",
    path: "/ka/programs/master",
    meta: mastermhOBGKAUEAMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/programs/master.vue")
  },
  {
    name: "programs-non-degree___en",
    path: "/en/programs/non-degree",
    meta: non_45degreeihafvA3mW5Meta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/programs/non-degree.vue")
  },
  {
    name: "programs-non-degree___ka",
    path: "/ka/programs/non-degree",
    meta: non_45degreeihafvA3mW5Meta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/programs/non-degree.vue")
  },
  {
    name: "programs-one-cycle___en",
    path: "/en/programs/one-cycle",
    meta: one_45cycleanIXxcYJLdMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/programs/one-cycle.vue")
  },
  {
    name: "programs-one-cycle___ka",
    path: "/ka/programs/one-cycle",
    meta: one_45cycleanIXxcYJLdMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/programs/one-cycle.vue")
  },
  {
    name: "programs-phd___en",
    path: "/en/programs/phd",
    meta: phdm4SKUKBJyFMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/programs/phd.vue")
  },
  {
    name: "programs-phd___ka",
    path: "/ka/programs/phd",
    meta: phdm4SKUKBJyFMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/programs/phd.vue")
  },
  {
    name: "projects-slug___en",
    path: "/en/projects/:slug(.*)*",
    meta: _91_46_46_46slug_93TjG90ApfV7Meta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/projects/[...slug].vue")
  },
  {
    name: "projects-slug___ka",
    path: "/ka/projects/:slug(.*)*",
    meta: _91_46_46_46slug_93TjG90ApfV7Meta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/projects/[...slug].vue")
  },
  {
    name: "projects___en",
    path: "/en/projects",
    meta: indexhGAO1p7SMmMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/projects/index.vue")
  },
  {
    name: "projects___ka",
    path: "/ka/projects",
    meta: indexhGAO1p7SMmMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/projects/index.vue")
  },
  {
    name: "projects-legal-ethics-chair-about___en",
    path: "/en/projects/legal-ethics-chair/about",
    meta: about8bvNeqABz7Meta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/projects/legal-ethics-chair/about.vue")
  },
  {
    name: "projects-legal-ethics-chair-about___ka",
    path: "/ka/projects/legal-ethics-chair/about",
    meta: about8bvNeqABz7Meta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/projects/legal-ethics-chair/about.vue")
  },
  {
    name: "projects-legal-ethics-chair-activities___en",
    path: "/en/projects/legal-ethics-chair/activities",
    meta: activitiesjXJDYS9Ks2Meta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/projects/legal-ethics-chair/activities.vue")
  },
  {
    name: "projects-legal-ethics-chair-activities___ka",
    path: "/ka/projects/legal-ethics-chair/activities",
    meta: activitiesjXJDYS9Ks2Meta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/projects/legal-ethics-chair/activities.vue")
  },
  {
    name: "projects-legal-ethics-chair-contact___en",
    path: "/en/projects/legal-ethics-chair/contact",
    meta: contactAnufUMPGPfMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/projects/legal-ethics-chair/contact.vue")
  },
  {
    name: "projects-legal-ethics-chair-contact___ka",
    path: "/ka/projects/legal-ethics-chair/contact",
    meta: contactAnufUMPGPfMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/projects/legal-ethics-chair/contact.vue")
  },
  {
    name: "projects-legal-ethics-chair-gallery___en",
    path: "/en/projects/legal-ethics-chair/gallery",
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/projects/legal-ethics-chair/gallery.vue")
  },
  {
    name: "projects-legal-ethics-chair-gallery___ka",
    path: "/ka/projects/legal-ethics-chair/gallery",
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/projects/legal-ethics-chair/gallery.vue")
  },
  {
    name: "projects-legal-ethics-chair-legal-aid-clinic___en",
    path: "/en/projects/legal-ethics-chair/legal-aid-clinic",
    meta: legal_45aid_45clinic1MObu3IUgUMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/projects/legal-ethics-chair/legal-aid-clinic.vue")
  },
  {
    name: "projects-legal-ethics-chair-legal-aid-clinic___ka",
    path: "/ka/projects/legal-ethics-chair/legal-aid-clinic",
    meta: legal_45aid_45clinic1MObu3IUgUMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/projects/legal-ethics-chair/legal-aid-clinic.vue")
  },
  {
    name: "projects-legal-ethics-chair-library-slug___en",
    path: "/en/projects/legal-ethics-chair/library/:slug()",
    meta: _91slug_93xn605EHwAzMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/projects/legal-ethics-chair/library/[slug].vue")
  },
  {
    name: "projects-legal-ethics-chair-library-slug___ka",
    path: "/ka/projects/legal-ethics-chair/library/:slug()",
    meta: _91slug_93xn605EHwAzMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/projects/legal-ethics-chair/library/[slug].vue")
  },
  {
    name: "projects-legal-ethics-chair-library___en",
    path: "/en/projects/legal-ethics-chair/library",
    meta: indexKSZdVtJOTdMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/projects/legal-ethics-chair/library/index.vue")
  },
  {
    name: "projects-legal-ethics-chair-library___ka",
    path: "/ka/projects/legal-ethics-chair/library",
    meta: indexKSZdVtJOTdMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/projects/legal-ethics-chair/library/index.vue")
  },
  {
    name: "projects-legal-ethics-chair-news___en",
    path: "/en/projects/legal-ethics-chair/news",
    meta: newsqnCJJEmt2vMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/projects/legal-ethics-chair/news.vue")
  },
  {
    name: "projects-legal-ethics-chair-news___ka",
    path: "/ka/projects/legal-ethics-chair/news",
    meta: newsqnCJJEmt2vMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/projects/legal-ethics-chair/news.vue")
  },
  {
    name: "projects-legal-ethics-chair-staff___en",
    path: "/en/projects/legal-ethics-chair/staff",
    meta: staffM4CKS783hLMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/projects/legal-ethics-chair/staff.vue")
  },
  {
    name: "projects-legal-ethics-chair-staff___ka",
    path: "/ka/projects/legal-ethics-chair/staff",
    meta: staffM4CKS783hLMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/projects/legal-ethics-chair/staff.vue")
  },
  {
    name: "projects-olympiads-slug___en",
    path: "/en/projects/olympiads/:slug(.*)*",
    meta: _91_46_46_46slug_93nBaNwGj3LiMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/projects/olympiads/[...slug].vue")
  },
  {
    name: "projects-olympiads-slug___ka",
    path: "/ka/projects/olympiads/:slug(.*)*",
    meta: _91_46_46_46slug_93nBaNwGj3LiMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/projects/olympiads/[...slug].vue")
  },
  {
    name: "projects-olympiads___en",
    path: "/en/projects/olympiads",
    meta: indexfR1Qz6P2U9Meta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/projects/olympiads/index.vue")
  },
  {
    name: "projects-olympiads___ka",
    path: "/ka/projects/olympiads",
    meta: indexfR1Qz6P2U9Meta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/projects/olympiads/index.vue")
  },
  {
    name: "research-and-resources-e-resources___en",
    path: "/en/research-and-resources/e-resources",
    meta: indexNe2uir0HXPMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/research-and-resources/e-resources/index.vue")
  },
  {
    name: "research-and-resources-e-resources___ka",
    path: "/ka/research-and-resources/e-resources",
    meta: indexNe2uir0HXPMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/research-and-resources/e-resources/index.vue")
  },
  {
    name: "research-and-resources___en",
    path: "/en/research-and-resources",
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/research-and-resources/index.vue")
  },
  {
    name: "research-and-resources___ka",
    path: "/ka/research-and-resources",
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/research-and-resources/index.vue")
  },
  {
    name: "research-and-resources-publications-slug___en",
    path: "/en/research-and-resources/publications/:slug(.*)*",
    meta: _91_46_46_46slug_93X67RxEogn1Meta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/research-and-resources/publications/[...slug].vue")
  },
  {
    name: "research-and-resources-publications-slug___ka",
    path: "/ka/research-and-resources/publications/:slug(.*)*",
    meta: _91_46_46_46slug_93X67RxEogn1Meta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/research-and-resources/publications/[...slug].vue")
  },
  {
    name: "research-and-resources-publications___en",
    path: "/en/research-and-resources/publications",
    meta: indexERsg6trUP9Meta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/research-and-resources/publications/index.vue")
  },
  {
    name: "research-and-resources-publications___ka",
    path: "/ka/research-and-resources/publications",
    meta: indexERsg6trUP9Meta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/research-and-resources/publications/index.vue")
  },
  {
    name: "research-and-resources-research-centers___en",
    path: "/en/research-and-resources/research/centers",
    meta: centers22OtpV5J9CMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/research-and-resources/research/centers.vue")
  },
  {
    name: "research-and-resources-research-centers___ka",
    path: "/ka/research-and-resources/research/centers",
    meta: centers22OtpV5J9CMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/research-and-resources/research/centers.vue")
  },
  {
    name: "research-and-resources-research___en",
    path: "/en/research-and-resources/research",
    meta: indexqovqhIiKmYMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/research-and-resources/research/index.vue")
  },
  {
    name: "research-and-resources-research___ka",
    path: "/ka/research-and-resources/research",
    meta: indexqovqhIiKmYMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/research-and-resources/research/index.vue")
  },
  {
    name: "research-and-resources-research-projects___en",
    path: "/en/research-and-resources/research/projects",
    meta: projectsbeqfDoSSD6Meta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/research-and-resources/research/projects.vue")
  },
  {
    name: "research-and-resources-research-projects___ka",
    path: "/ka/research-and-resources/research/projects",
    meta: projectsbeqfDoSSD6Meta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/research-and-resources/research/projects.vue")
  },
  {
    name: "research-projects-descnet___en",
    path: "/en/research-projects/descnet",
    meta: descnetY96Fm7G7RwMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/research-projects/descnet.vue")
  },
  {
    name: "research-projects-descnet___ka",
    path: "/ka/research-projects/descnet",
    meta: descnetY96Fm7G7RwMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/research-projects/descnet.vue")
  },
  {
    name: "research-projects-jean-monnet___en",
    path: "/en/research-projects/jean-monnet",
    meta: jean_45monnet6d659i4dAMMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/research-projects/jean-monnet.vue")
  },
  {
    name: "research-projects-jean-monnet___ka",
    path: "/ka/research-projects/jean-monnet",
    meta: jean_45monnet6d659i4dAMMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/research-projects/jean-monnet.vue")
  },
  {
    name: "schools-slug___en",
    path: "/en/schools/:slug()",
    meta: _91slug_93OFE4nc1NrTMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/schools/[slug].vue")
  },
  {
    name: "schools-slug___ka",
    path: "/ka/schools/:slug()",
    meta: _91slug_93OFE4nc1NrTMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/schools/[slug].vue")
  },
  {
    name: "staff-slug___en",
    path: "/en/staff/:slug()",
    meta: _91slug_93MM94pZ1EZyMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/staff/[slug].vue")
  },
  {
    name: "staff-slug___ka",
    path: "/ka/staff/:slug()",
    meta: _91slug_93MM94pZ1EZyMeta || {},
    component: () => import("A:/Solutions/NewVision/newvision.ge/pages/staff/[slug].vue")
  },
  {
    name: component_45stubPFFdYwiFpzMeta?.name,
    path: "/en/policies-and-reports/regulations",
    component: component_45stubPFFdYwiFpz
  },
  {
    name: component_45stubPFFdYwiFpzMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubPFFdYwiFpz
  }
]